/**
 * Helpers
 */
import { onVisibilityChange } from './helpers'

/**
 * Web standards
 */
import './menu'
import './lazyload'
import './cookieconsent'

/**
 * Scripts which doesn't need dynamic import
 */
import {  Modal } from 'bootstrap';

/**
 * Dynamic import scripts
 */
const elements = [
  '.has-js-flip',
  '.googlemap_textright'
];

[].forEach.call(elements, (element) => {

  if (document.querySelector(element) !== null && document.querySelector(element) !== undefined) {

    const lazyloadHandler = onVisibilityChange(document.querySelector(element), function () {

      // Example library without init:
      if (element === '.has-js-flip') {
        const Filters = import('./flip')
      }
      
      if (element === '.googlemap_textright') {
        const Filters = import('./googlemap')
      }

      // Example library with init:
      // if (element === '[data-aos]' || element === '.aos-element') {
      //   const AOS = import('aos').then(AOS => {
      //     AOS.init();
      //   });
      // }
    })

    if (window.addEventListener) {
      addEventListener('DOMContentLoaded', lazyloadHandler, false);
      addEventListener('load', lazyloadHandler, false);
      addEventListener('scroll', lazyloadHandler, false);
      addEventListener('resize', lazyloadHandler, false);
    } else if (window.attachEvent) {
      attachEvent('onDOMContentLoaded', lazyloadHandler); // Internet Explorer 9+ :(
      attachEvent('onload', lazyloadHandler);
      attachEvent('onscroll', lazyloadHandler);
      attachEvent('onresize', lazyloadHandler);
    }
  }
});
jQuery(document).ready(function($){
  $('.btn-topbar').on("click", function(){
    $(this).toggleClass('is-open-topbar');
    $('.header__top').toggleClass('open-topbar');
  });

  $('.search-btn').on("click", function(){
    $('.modal-search').addClass('modal-is-active');
  });
  $('.modal-overlay, .modal-close').on("click", function(){
    $('.modal').removeClass('modal-is-active');
    $('body').removeClass('overflow-hidden');
    $('body').removeClass('modal-open');
  });
  $('.open-modal-whitepaper').on("click", function(){
    $('body').addClass('overflow-hidden');
    $('body').addClass('modal-open');
    let get_modal_id = $(this).data("modal-id");
    let get_modal = '#modal-' + get_modal_id;
    $(get_modal).addClass('modal-is-active');
  });

  $('.btn-solliciteren').on("click", function(){
    $('body').addClass('overflow-hidden');
    let get_modal_id = $(this).data("modal-id");
    $('body').addClass('modal-open');
    let get_modal = '#modal-' + get_modal_id;
    $(get_modal).addClass('modal-is-active');
  });
  
  $('.show_full_text_without_work_experience').on("click", function(){
    $('.without_work_experience_join_us_section').addClass('show_full_text');
  });

  $('.show_full_text_with_work_experience').on("click", function(){
    $('.with_work_experience_join_us_section').addClass('show_full_text');
  });

  $('input[type="text"].js-transform-capitalize').keyup(function(event) {
    var textBox = event.target;
    var start = textBox.selectionStart;
    var end = textBox.selectionEnd;
    textBox.value = textBox.value.charAt(0).toUpperCase() + textBox.value.slice(1).toLowerCase();
    textBox.setSelectionRange(start, end);
  });
  
});
